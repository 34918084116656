body,
span,
p,
div {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Spectral", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spectral-extralight {
  font-family: "Spectral", serif;
  font-weight: 200;
  font-style: normal;
}

.spectral-light {
  font-family: "Spectral", serif;
  font-weight: 300;
  font-style: normal;
}

.spectral-regular {
  font-family: "Spectral", serif;
  font-weight: 400;
  font-style: normal;
}

.spectral-medium {
  font-family: "Spectral", serif;
  font-weight: 500;
  font-style: normal;
}

.spectral-semibold {
  font-family: "Spectral", serif;
  font-weight: 600;
  font-style: normal;
}

.spectral-bold {
  font-family: "Spectral", serif;
  font-weight: 700;
  font-style: normal;
}

.spectral-extrabold {
  font-family: "Spectral", serif;
  font-weight: 800;
  font-style: normal;
}

.spectral-extralight-italic {
  font-family: "Spectral", serif;
  font-weight: 200;
  font-style: italic;
}

.spectral-light-italic {
  font-family: "Spectral", serif;
  font-weight: 300;
  font-style: italic;
}

.spectral-regular-italic {
  font-family: "Spectral", serif;
  font-weight: 400;
  font-style: italic;
}

.spectral-medium-italic {
  font-family: "Spectral", serif;
  font-weight: 500;
  font-style: italic;
}

.spectral-semibold-italic {
  font-family: "Spectral", serif;
  font-weight: 600;
  font-style: italic;
}

.spectral-bold-italic {
  font-family: "Spectral", serif;
  font-weight: 700;
  font-style: italic;
}

.spectral-extrabold-italic {
  font-family: "Spectral", serif;
  font-weight: 800;
  font-style: italic;
}
